// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("css-sprites-sort.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-arrows-default, .sort-arrows-desc, .sort-arrows-asc 
{ display: inline-block; background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }
 
.sort-arrows-default { background-position: -0px -0px; width: 16px; height: 16px; }
.sort-arrows-desc { background-position: -0px -16px; width: 16px; height: 16px; }
.sort-arrows-asc { background-position: -0px -32px; width: 16px; height: 16px; }

`, "",{"version":3,"sources":["webpack://./logician_coder/static/css/css-sprites-sort.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB,EAAE,6DAAiD,EAAE,gBAAgB,EAAE,oBAAoB,EAAE,gBAAgB,EAAE;;AAEtI,uBAAuB,8BAA8B,EAAE,WAAW,EAAE,YAAY,EAAE;AAClF,oBAAoB,+BAA+B,EAAE,WAAW,EAAE,YAAY,EAAE;AAChF,mBAAmB,+BAA+B,EAAE,WAAW,EAAE,YAAY,EAAE","sourcesContent":[".sort-arrows-default, .sort-arrows-desc, .sort-arrows-asc \n{ display: inline-block; background: url('css-sprites-sort.png') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }\n \n.sort-arrows-default { background-position: -0px -0px; width: 16px; height: 16px; }\n.sort-arrows-desc { background-position: -0px -16px; width: 16px; height: 16px; }\n.sort-arrows-asc { background-position: -0px -32px; width: 16px; height: 16px; }\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
